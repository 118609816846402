import { ref, computed } from '@vue/composition-api'
import store from '@/store'            

export default function getNavItems() {
  const sidebarItems = computed(() => {
    var getPermission = store.getters.getPermissionModules
    let count = 0;
    var itemObj = {}
    var itemArr = [];
    for (var key in getPermission) {
      itemObj = {
        title: getPermission[key]['name'],
        route: getPermission[key]['route_name'],
        icon: null
      };
      itemArr.push(itemObj)
      count++; 
    }
    return itemArr;
  });
  return {
    sidebarItems
  }
}
// export default [
// {
//   title: 'Home',
//   route: 'home',
//   icon: 'HomeIcon',
// },
// {
//   title: 'Second Page',
//   route: 'second-page',
//   icon: 'FileIcon',
// },
// ]