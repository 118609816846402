<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!--<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{username}}
            </p>
            <span class="user-status">{{role}}</span>
          </div>
          <b-avatar
            size="40"
            badge
            v-if="profileImg"
            :src="base_url+''+profileImg"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            size="40"
            badge
            v-else
            :src="null"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          :to="{ name: 'edit-student-profile'}"
          link-class="d-flex align-items-center"
          v-if = "role == 'student'"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Edit Profile</span>
        </b-dropdown-item>
       <b-dropdown-item
          :to="{ name: 'edit-teacher-profile'}"
          link-class="d-flex align-items-center"
          v-if = "role == 'teacher'"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Edit Profile</span>
        </b-dropdown-item>
        <b-dropdown-item 
          link-class="d-flex align-items-center"
          :to="{ name: 'change-password'}"
          v-if = "role == 'teacher' || role == 'student'"
        >
          <feather-icon
            size="16"
            icon="UnlockIcon"
            class="mr-50"
          />
          <span>Edit Password</span>
        </b-dropdown-item>
        <!--<b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>
        -->
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler
  },
  data() {
    return {
      username: '',
      role: '',
      showRole: '',
      base_url: 'https://aula.anclademia.com/public/uploads/public/userProfile/',
     //base_url: 'http://localhost:8000/uploads/public/userProfile/',
     profileImg: '',
     userAvatar: require('@/assets/images/avatars/user-icon.png')
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(response => {
        this.$router.push('/login');
      }).catch(error => {
        console.log(error)
      });
    }
  },
  created () {
    this.username = this.$store.getters.getUser.name;
    this.role = this.$store.getters.getRole
    this.profileImg = this.$store.getters.getProfileImg;
    if(this.role == "new-student") {
      this.showRole = 'student';
    } else {
      this.showRole = this.$store.getters.getRole; 
    }
  }
}
</script>
